import React, { Fragment, memo, useEffect, useState } from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

const ArrowDown = memo(() => {
    const [show, setShow] = useState(true);

    useEffect(() => {
        const id = setTimeout(() => setShow(prev => !prev), 2000);
        return () => clearTimeout(id);
    }, [])

    return <Fragment>
        {show && <FaCaretDown className='text-danger' />}
    </Fragment>
});

const ArrowUp = memo(() => {
    const [show, setShow] = useState(true);

    useEffect(() => {
        const id = setTimeout(() => setShow(prev => !prev), 2000);
        return () => clearTimeout(id);
    }, [])

    return <Fragment>
        {show && <FaCaretUp className='text-warning' />}
    </Fragment>
});

const default_odd_value = Number(0).toFixed(2);


function Odd({ lv, v }) {
    const curr = Number.isFinite(v) ? v.toFixed(2) : default_odd_value;
    const prev = Number.isFinite(lv) ? lv.toFixed(2) : default_odd_value;

    return <span>
        {curr}
        {curr < prev && <ArrowDown />}
        {curr > prev && <ArrowUp />}
    </span>
}

export default Odd;