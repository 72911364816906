import React, { Fragment } from 'react';

const Time = ({ period, minutes }) => {
	if (minutes < 0)
	{
		return <Fragment></Fragment>
	}
	
	const time_str = period === 2 ? 'HT' : period === 4 ? 'FT' : `${minutes}'`;

	return <span className="fw-bold text-warning">
		{time_str}
	</span>;
};

export default Time;
