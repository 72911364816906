import React, { Fragment } from 'react';
import {
	BookingCards,
	GoalGoal,
	MatchResult,
	NextGoal,
	OverUnder,
	Period,
	Scores,
	Time,
} from './index';

import {
	getHalfTimeOddsMatchResult,
	get_full_time_goals,
	get_half_time_goals,
	get_red_cards
} from '../functions';

const is_dangerous_attack = st => st === "11008" || st === "21008";
const is_goal = st => st === "10003" || st === "20003";

function Odds({ game, minutes, total_goals }) {
	if (minutes < 0) {
		const min = Math.abs(minutes);
		let msg  = 'starting in ' + min + ' minute';
		msg += min > 1 ? 's': '';

		return <td className='text-center' colSpan='11'>{msg}</td>;
	}

	if (is_goal(game.st))
	{
		return <td className="goalCss" colSpan="11">{game.stn}</td>;
	}

	if (is_dangerous_attack(game.st)) {
		return <td className="dangerCss" colSpan="11">{game.stn}</td>;
	}

	return <Fragment>
		<MatchResult blocked={game.b} odds={game.bt} />
		<OverUnder blocked={game.b} odds={game.bt} />
		<GoalGoal blocked={game.b} odds={game.bt} />
		<NextGoal blocked={game.b} odds={game.bt} totalGoals={total_goals} />
	</Fragment>
}

function GridRow({ event }) {
	const game = event.obj[0];

	const { length: totalHalfTimeOdds } = getHalfTimeOddsMatchResult(game.bt);
	const [t1_red, t2_red] = get_red_cards(game.sc);
	const total_goals = game.sc.GOAL[0] + game.sc.GOAL[1];

	const h1_goals = get_half_time_goals(game.sc);
	const ft_goals = get_full_time_goals(game.sc);

	const minutes = Math.floor(game.tm / 60);

	return (
		<tr key={game.std} data-match-time={game.tss}>
			<td>{game.cd}</td>
			<td>
				<Period period={game.p} />
			</td>
			<td>
				<Time period={game.p} minutes={minutes} />
			</td>
			<td>
				<span>{game.t1.n} <BookingCards cardType="RED_CARD" totalCards={t1_red} /></span>
				<br />
				<span>{game.t2.n} <BookingCards cardType="RED_CARD" totalCards={t2_red} /></span>
			</td>
			<td className='text-center'>
				<Scores goal={ft_goals} h1={h1_goals} hasHalfTimeOdds={totalHalfTimeOdds > 0}
				 	minutes={minutes}
					period={game.p} />
			</td>
			<Odds game={game} minutes={minutes} total_goals={total_goals} />
		</tr>
	);
};

export default GridRow;
