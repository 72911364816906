import React from "react";
import { getNextGoalOdds, getNextGoalOptionOdd } from "../functions";

const NextGoal = ({ blocked, odds, totalGoals }) => {
	const nextGoalOdds = getNextGoalOdds(odds, totalGoals);
	if (blocked === 0 && nextGoalOdds.length > 0) {
		const options = ["1", "No goal", "2"].map(x => getNextGoalOptionOdd(x, nextGoalOdds));
		return <>
			{options.map(({ n, v }) => <td className="opacity-90 text-center" key={n}>
				{v.toFixed(2)}
				<br />

			</td>)}
		</>

	}
	return <td className="opacity-90" colSpan="3" />;
};

export default NextGoal;
