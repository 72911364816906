export const MARKET_1X2 = '1x2';
export const MARKET_1X2_H1 = '1x2_H1';
export const MARKET_1X2_H2 = '1X2_H2';
export const MARKET_BTS = 'BTS';
export const MARKET_BTS_H1 = 'BTS_H1';
export const MARKET_BTS_H2 = 'BTS_H2';
export const MARKET_DC = 'DC';
export const MARKET_DC_BTS = 'DC_BTS';
export const MARKET_DC_H1 = 'DC_H1';
export const MARKET_DC_H2 = 'DC_H2';
export const MARKET_DC_OU50 = 'DC_OU50';
export const MARKET_DNB = 'DNB';
export const MARKET_EH = 'EH';
export const MARKET_HSH = 'HSH';
export const MARKET_HTFT = 'HTFT';
export const MARKET_OE = 'OE';
export const MARKET_OE_H1 = 'OE_H1';
export const MARKET_OE_H2 = 'OE_H2';
export const MARKET_OU = 'OU';
export const MARKET_OU_H1 = 'OU_H1';
export const MARKET_OU_H2 = 'OU_H2';
export const MARKET_RBTS = 'RBTS';
export const MARKET_RTG = 'RTG';
export const MARKET_TGBTS = 'TGBTS';
export const MARKET_RESTOFMATCH = 'RES_1x2';
export const MARKET_NEXTGOAL = (goal) => `S${goal}G`;
export const MARKET_HALFTIME_NEXTGOAL = (goal) => `S${goal}G_H1`;
export const ONLINE_MARKETS = {
    MARKET_1X2: 'Match Result',
    MARKET_1X2_H1: 'Half Time Result',
    MARKET_1X2_H2: '2nd Half Result',
};
