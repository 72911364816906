import React, { Fragment } from "react";

const Scores = ({ goal, h1, hasHalfTimeOdds, minutes, period }) => {
	// do not display if the game has not yet started
	if (minutes < 0) {
		return <Fragment></Fragment>
	}

	// display scores - if game is in progress
	return <Fragment>
		<span className="text-warning">
			{goal[0]}:{goal[1]}
		</span>
		<br />
		{(hasHalfTimeOdds && period < 2) ? (<span>HT</span>) : (period > 2) ? <>({h1[0]}:{h1[1]})</> : <></>}
	</Fragment>
};

export default Scores;
