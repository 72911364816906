import { io } from 'socket.io-client';
import { liveGamesStorage } from './rxjs-stores';
import { WS_URL } from './environment';
import { get_proto_root, toUint8Array } from './functions';

function onConnect() {
	console.log('connected to socket');
};

function onDiffReceivedHandler(message) {
	const obj = JSON.parse(message);
	liveGamesStorage.updateEvent(obj);
}

const onExpiredGamesEvent = (payload) => {
	if (!payload) {
		return;
	}

	if (payload.indexOf('live_game_') === 1)
	{
		liveGamesStorage.removeEvent(payload.replace('live_game_', ''));
		return;
	}

	if (payload.indexOf('buffer_') === 1) {
		liveGamesStorage.removeEvent(payload.replace('buffer_', ''));
		return;
	}
};

const onGameReceivedHandler = async (message) => {
	const buffer = toUint8Array(message);
	const root = await get_proto_root();

	const EventType = root.lookupType('bet720_protocol_buffers.Event');

	const event_message = EventType.decode(buffer);
	const event_pojo = EventType.toObject(event_message, {
		arrays: true,
		objects: true
	});
	
	if (event_pojo.p !== 'ALL' || !event_pojo?.obj) return;

	const { inf } = event_pojo.obj[0];

	if (inf && inf.indexOf('Esoccer') > -1) return;

	event_pojo.obj[0].sc = {
		CORNER: event_pojo.obj[0]['sc']['corner'],
        FREE_KICK: event_pojo.obj[0]['sc']['freeKick'],
        GOAL: event_pojo.obj[0]['sc']['goal'],
        GOAL_KICK: event_pojo.obj[0]['sc']['goalKick'],
        H1: event_pojo.obj[0]['sc']['h1'],
        OFFSIDE: event_pojo.obj[0]['sc']['offside'],
        PENALTY: event_pojo.obj[0]['sc']['penalty'],
        RED_CARD: event_pojo.obj[0]['sc']['redCard'],
        SUBSTITUTION: event_pojo.obj[0]['sc']['substitution'],
        THROW_IN: event_pojo.obj[0]['sc']['throwIn'],
        YELLOW_CARD: event_pojo.obj[0]['sc']['yellowCard'],
	};

	liveGamesStorage.addOrUpdate(event_pojo);
};

const connect = (() => {
	let socket;
	/**
	 * returns a setup function,
	 * closes over the socket
	 */
	return () => {
		if (socket) return socket;
		//initialize if not already initialized	
		socket = io(WS_URL)
		socket.on('connect', onConnect);										// when we connect
		socket.on('expired-key', onExpiredGamesEvent);							// when a key expires
		socket.on('buffers', onGameReceivedHandler);							// on initial connect - get all protobuf message
		socket.on('buffer-diffs', onDiffReceivedHandler);						// handle diffs
		return socket;
	};
})();

export default connect;
