import { liveGames$ } from '.';

const liveGamesStorage = (() => {
	let events = [];

	/**
	 * add a game
	 * @param {object} json
	 */
	const addOrUpdate = (json) => {
		const { std: matchId } = json.obj[0];
		
		if (!matchId) return;
		
		const index = events.findIndex(x => x.obj[0].std === matchId);
		if (index > -1) {
			events[index] = json;
		} else {
			events.push(json);
		}
		
		liveGames$.next(events);
	};

	const removeEvent = (matchId) => {
		events = events.filter(x => x.obj[0].std !== matchId);
		liveGames$.next(events);
	};

	function updateEvent(json) {
		let event = events.find(x => x.obj[0].std === json.std); 
		if (!event) return;

		const game = event.obj[0];
		const new_event = { ...event };

		const sc = json.sc;
		delete json.sc;

		new_event.obj[0] = { ...game, ...json };

		if (sc) {
			new_event.obj[0].sc = { ...new_event.obj[0].sc, ...sc };
		}
		
		event = new_event;
		liveGames$.next(events);
	}

	return {
		addOrUpdate, removeEvent, updateEvent
	};
})();

export default liveGamesStorage;
