import React from "react";
import { getFullTimeOddsMatchResult, getHalfTimeOddsMatchResult } from "../functions";
import { Odd } from './index';

const MatchResult = ({ blocked, odds }) => {
	const ht_odds = getHalfTimeOddsMatchResult(odds);
	const ft_odds = getFullTimeOddsMatchResult(odds);

	if (blocked === 0 && ft_odds.length > 0) {
		return ft_odds.map(({ lv, n, v }, index) => <td className="text-center" key={n}>
			<Odd lv={lv} v={v} />
			<br />
			{(ht_odds.length > 0 && ht_odds[index] !== undefined) && <Odd lv={ht_odds[index].lv} v={ht_odds[index].v} />}
		</td>);
	}

	return <td colSpan="3"></td>;
};

export default MatchResult;
