import protobuf from 'protobufjs';
import { MARKET_1X2, MARKET_1X2_H1, MARKET_NEXTGOAL, MARKET_OU, MARKET_OU_H1 } from "./markets";

export const get_proto_root = (() => {
    let root;
    
    return async () => { 
        if (!root) 
        {
            console.log('loading root for the first time');
            const f_names = ['event', 'game', 'market', 'odd', 'scores', 'team']
                .map(x => process.env.PUBLIC_URL + '/protos/' +  x + '.proto');

            root = protobuf.load(f_names);
        }

        return root;
    }
})();

/**
 * converts a base64 encoded string into a uint8 array
 * @param {String} base64 
 * @returns {Uint8Array}
 */
export function toUint8Array(base64) {
    // Decode the base64 string to a binary string
    const binaryString = atob(base64);
    
    // Create a Uint8Array with the same length as the binary string
    const uint8Array = new Uint8Array(binaryString.length);
    
    // Convert each character in the binary string to a byte in the Uint8Array
    for (let i = 0; i < binaryString.length; i++) {
        uint8Array[i] = binaryString.charCodeAt(i);
    }

    return uint8Array;
}

const DEFAULT_SCORES_PROP_VALUE = [0, 0];

export const get_half_time_goals = (scores) => {
	return scores?.['H1'] ?? DEFAULT_SCORES_PROP_VALUE;
}

export const get_full_time_goals = (scores) => {
	return scores?.['GOAL'] ?? DEFAULT_SCORES_PROP_VALUE;
}

export const get_page_events = (events_arr, page = 1, size = 10) => {
	const sorted = events_arr.sort((a , b) => b.obj[0].tm - a.obj[0].tm);

	const start = --page * size;
	const end = start + size;
	const page_events = sorted.slice(start, end);

	return page_events;
};

export const get_red_cards = scores => {
	return scores?.['RED_CARD'] ?? DEFAULT_SCORES_PROP_VALUE;
};

// functions to extract odds
export const getFullTimeOddsMatchResult = (odds) => {
	const oddsGroup = odds ? odds.filter(({ n }) => n === MARKET_1X2) : [];

	return oddsGroup.length === 0
		? []
		: oddsGroup[0]['o'].map((x) => ({ ...x, Odd: x.v }));
};

export const getHalfTimeOddsMatchResult = (odds) => {
	const oddsGroup = odds
		? odds.filter(({ n }) => n === MARKET_1X2_H1)
		: [];

	return oddsGroup.length === 0
		? []
		: oddsGroup[0]['o'].map((x) => ({ ...x, Odd: x.v }));
};

const sortFunction = (a, b) => (a.h > b.h ? 1 : -1);

export const getFullTimeOddsUnderOver = (odds) => {
	const oddsGroup = odds
		? odds.filter(({ n, h }) => n === MARKET_OU && h > 1.2).sort(sortFunction)
		: [];

	const line = oddsGroup.length === 0 ? 0 : oddsGroup[0]['h'];
	const wireOdds =
		oddsGroup.length === 0
			? []
			: oddsGroup[0]['o'].map((x) => ({ ...x, Odd: x.v })).reverse();

	return { line, wireOdds };
};

export const getHalfTimeOddsUnderOver = (odds) => {
	const oddsGroup = odds
		? odds.filter(({ n }) => n === MARKET_OU_H1).sort(sortFunction)
		: [];

	const line = oddsGroup.length === 0 ? 0 : oddsGroup[0]['h'];
	const wireOdds =
		oddsGroup.length === 0
			? []
			: oddsGroup[0]['o'].map((x) => ({ ...x, Odd: x.v })).reverse();

	return { line, wireOdds };
};

export const getGoalGoalOdds = (odds, market) => {
	const oddsBTSGroup = odds ? odds.filter(({ n }) => n === market) : [];

	return oddsBTSGroup.length === 0
		? []
		: oddsBTSGroup[0]['o'].map((x) => ({ ...x, Odd: x.v }));
}

export const getNextGoalOdds = (odds, totalGoals) => {
	const nextGOAL = MARKET_NEXTGOAL(totalGoals + 1);

	const oddsNextGoalGroup = odds ? odds.filter(({ n }) => n === nextGOAL) : [];
	return oddsNextGoalGroup.length === 0
		? []
		: oddsNextGoalGroup[0]['o'].map((x) => ({ ...x, Odd: x.v }));
};

export const getNextGoalOptionOdd = (option, odds) => {
	var selectedOddIndex = odds.findIndex(({ n }) => n === option)
	return odds[selectedOddIndex];
}
