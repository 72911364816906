import React from 'react';
import { Container, Table } from 'react-bootstrap';
import { useDate, useLiveGames, usePage } from '../hooks';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import { get_page_events } from '../functions';
import { GridRow, Pager } from '.';
import logo from '../dark_logo.png';

const Grid = () => {
	const { date } = useDate();
	const { liveGames, totalGames } = useLiveGames();
	const { page, totalPages } = usePage(60 * 1000, 10, totalGames);

	if (totalGames === 0) {
		return <div />;
	}

	const page_events = get_page_events(liveGames, page);
	if (page_events.length === 0) {
		return <div />;
	}

	return <Container fluid="fluid" className="p-0">
		<Table className="table table-borderless table-condensed table-striped"
			id="MatchTable"
			hover
			responsive
			striped>
			<thead>
				<tr className="tablehead">
					<th colSpan="3"><img src={logo} alt="Logo" width={150} /></th>
					<th colSpan="2"> {date.toLocaleString()} </th>
					<th colSpan="3" className="text-center">Match Odds</th>
					<th />
					<th colSpan="2" className="text-center">Under/Over</th>
					<th colSpan="2" className="text-center">Goal Goal</th>
					<th colSpan="3" className="text-center">Next&nbsp;Goal</th>
				</tr>
				<tr className="tablehead">
					<th colSpan="3" className="text-center" />
					<th className="matchStyle">Game</th>
					<th className="text-center">Score</th>
					<th className="text-center">1</th>
					<th className="text-center">X</th>
					<th className="text-center">2</th>
					<th className="text-center" />
					<th className="text-center">
						<FaCaretDown style={{ color: '#fff', fontSize: '25px' }} />
					</th>
					<th className="text-center">
						<FaCaretUp style={{ color: '#fff', fontSize: '25px' }} />
					</th>
					<th className="text-center">Yes</th>
					<th className="text-center">No</th>
					<th className="text-center">1</th>
					<th className="text-center">No Goal</th>
					<th className="text-center">2</th>

				</tr>
			</thead>
			<tbody className="fw-bold text-white">
				{page_events
					.filter(json => {
						const markets = json.obj[0]?.bt;
						return Array.isArray(markets) && markets.length > 0;
					})
					.map((json) => <GridRow event={json} />)
				}
			</tbody>
		</Table>
		<Pager page={page} totalGames={totalGames} totalPages={totalPages} />
	</Container>;
}

export default Grid;
